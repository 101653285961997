<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" title="调价" @closed="cancel" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="110px" v-if="form" :disabled="true">
      <el-tabs v-model="currentTab">
        <el-tab-pane label="批发价" name="basic">
          <el-row>
            <el-col :span="12">
              <el-form-item prop="goodsPrice" label="批发价">
                <price-input :min="0" size="small" v-model="form.goodsPrice " style="width: 250px;" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="_dateRange" label="开始日期">
                <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="form._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="经销商定价" name="second">
          <el-table v-loading="loading" :data="form.distributorPrice" size="small" style="width: 100%;">
            <el-table-column label="经销商类型" prop="name" width="100" />
            <el-table-column label="价格" width="200">
              <template slot-scope="scope">
                <price-input :min="0" :disabled="!scope.row.enable" v-model="scope.row.goodsPrice" size="small" />
              </template>
            </el-table-column>
            <el-table-column label="开始日期">
              <template slot-scope="scope">
                <el-date-picker size="small" :disabled="!scope.row.enable" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row.beginTime" style="width: 100%;" />
              </template>
            </el-table-column>
            <el-table-column label="结束日期">
              <template slot-scope="scope">
                <el-date-picker size="small" :disabled="!scope.row.enable" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row.endTime" style="width: 100%;" />
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <!--分页组件-->
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog=false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { add } from "@/api/adjust";
import request from "@/utils/request";

export default {
  data() {
    return {
      currentTab: "basic",
      dialog: false,
      saving: false,
      loading: false,
      priceSaving: false,
      form: {
        priceType: 0,
        goodsPrice: null,
        beginTime: "",
        endTime: "",
        _dateRange: [],
        distributorPrice: []
      },
      rules: {
        goodsPrice: [
          { required: "true", message: "批发价为必填项", trigger: "blur" }
        ]
      },
      readForm: {
        distributor: []
      }
    };
  },
  created() {
    this.getDealerData();
  },
  methods: {
    cancel() {
      this.currentTab = "basic";
      this.form.priceType = 0;
      this.form.goodsPrice = null;
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form._dateRange = [];
      this.form.distributorPrice = [];
    },
    handleSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.saving = true;
          let o = [];
          if (this.currentTab == "basic") {
            o = [
              {
                priceType: 0,
                goodsPrice: this.form.goodsPrice,
                beginTime: this.form.beginTime,
                endTime: this.form.endTime
              }
            ];
          } else {
            o = this.form.distributorPrice;
          }
          // 发送请求
          add(o)
            .then(res => {
              this.$notify({
                title: "确认成功",
                type: "success",
                duration: 2500
              });
              this.dialog = false;
              this.saving = false;
              this.$parent.init();
            })
            .catch(err => {
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 获取经销商类型数据
    getDealerData(data) {
      // console.log(data);
      if (data) {
        this.form._dateRange = [data.beginTime, data.endTime];
        this.form.goodsPrice = data.goodsPrice;
        request({
          url: "api/distributorLeve/list",
          method: "get",
          params: {
            page: 0,
            size: 1000
          }
        })
          .then(res => {
            if (res) {
              var obj = data.distributorPrice.map((item, index) => {
                return { ...item, ...res[index] };
              });
              this.form.distributorPrice = obj;
            }
          })
          .catch(err => {
            console.log(err.response.data.message);
          });
      }
    }
  }
};
</script>
